<template functional>
  <div>
    <h2 class="js-global-subtitle">Contact</h2>
    <div class="js-global-inner-section">
      <p>
        In case of a business or partnership proposition, I can be contacted via
        <a href="https://www.linkedin.com/in/jetrosuni/" rel="nofollow"
          >LinkedIn</a
        >
        or email (firstname.lastname@gmail.com)
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contact',
}
</script>
