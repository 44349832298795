var render = function (_h,_vm) {var _c=_vm._c;return _c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-three-fifths-mobile is-two-fifths-tablet is-one-fifth-widescreen is-size-6-widescreen"},[_c('div',{staticClass:"js-global-align-right is-hidden-mobile is-size-6-tablet"},[_vm._v(" "+_vm._s(_vm.props.title)+" ")]),_c('div',{staticClass:"js-global-align-left is-hidden-tablet is-size-7-desktop"},[_vm._v(" "+_vm._s(_vm.props.title)+" ")])]),_c('div',{staticClass:"column is-three-fifths is-two-fifths-desktop is-three-fifths-widescreen"},[_c('b-progress',{staticClass:"js-small-margin",attrs:{"size":"is-medium","type":_vm.props.type,"value":+(
          (_vm.$options.getExperienceMultiplier() * _vm.props.expInMonths) /
          12
        ).toFixed(1),"show-value":""}},[_c('span',{class:[
          _vm.props.type === 'is-development' || _vm.props.type === 'is-gaming'
            ? 'has-text-white'
            : 'has-text-black' ]},[_vm._v(_vm._s((_vm.props.expInMonths / 12).toFixed(1))+" years")])])],1),_c('div',{class:[
      _vm.props.type === 'is-it'
        ? 'column is-one-fifth is-hidden-mobile is-hidden-tablet'
        : 'column is-one-fifth' ]})])}
var staticRenderFns = []

export { render, staticRenderFns }