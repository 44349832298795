function getRangeAsArray(start, end) {
  return Array(end - start + 1).fill().map((_, idx) => start + idx)
}

const getExpInMonths = (expData, compDate) => {
  const finalYear = compDate.getFullYear()
  const years = getRangeAsArray(1979, finalYear)
  const uniqKeys = [...new Set([].concat.apply([], expData.map(item => item.keywords)))]
  const exp = uniqKeys.reduce((acc, curr) => (acc[curr] = 0, acc), {})
  years.map(y => {
    const months = y === finalYear ? getRangeAsArray(0, compDate.getMonth() - 1) : getRangeAsArray(0, 11)
    months.map((m) => {
      const date = new Date(y, m, 1);
      uniqKeys.map((key) => {
        let isAdd = false
        expData.map((e) => {
          if (e.keywords.includes(key) &&
            date >= e.startDate &&
            (!e.endDate || date < e.endDate)
          ) {
            isAdd = true
          }
        })
        if (isAdd) {
          exp[key]++
        }
      })
    })
  })
  return exp
}

export default { getExpInMonths }
