<template functional>
  <div class="js-photo" />
</template>

<script>
export default {
  Name: 'ProfilePhoto',
}
</script>

<style scoped>
.js-photo {
  width: 100%;
  height: 380px;
  border-radius: 10px;
  background-image: url(../assets/img/profile-photo-border.jpg);
  background-position-x: right;
  background-size: cover;
}
</style>
