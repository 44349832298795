<template>
  <div>
    <h2 class="js-global-subtitle">Core values</h2>
    <div class="js-core-desc js-global-inner-section">
      <div class="columns js-core-value">
        <div class="column is-full">
          <CoreValue
            icon="sync"
            pack="fas"
            title="Continuous improvement"
            iconClass="js-icon-ci"
            :lines="coreValueData.continuousImprovement.lines"
          />
        </div>
      </div>
      <div class="columns js-core-value">
        <div class="column is-full">
          <CoreValue
            icon="database"
            pack="fas"
            title="Data-informed"
            iconClass="js-icon-data"
            :lines="coreValueData.dataInformed.lines"
          />
        </div>
      </div>
      <div class="columns js-core-value">
        <div class="column is-full">
          <CoreValue
            icon="leanpub"
            pack="fab"
            title="Deliberate practice"
            iconClass="js-icon-practice"
            :lines="coreValueData.deliberatePractice.lines"
          />
        </div>
      </div>
      <div class="columns js-core-value">
        <div class="column is-full">
          <CoreValue
            icon="user-friends"
            pack="fas"
            title="Social intelligence"
            iconClass="js-icon-intelligence"
            :lines="coreValueData.socialIntelligence.lines"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column is-full">
          <CoreValue
            icon="handshake"
            pack="far"
            title="Trustworthiness"
            iconClass="js-icon-trust"
            :lines="coreValueData.trustworthiness.lines"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CoreValue from '@/components/CoreValue.vue'

import coreValueData from '@/data/coreValueData'

export default {
  name: 'CoreValues',
  data() {
    return {
      coreValueData,
    }
  },
  components: {
    CoreValue,
  },
}
</script>

<style scoped>
.js-core-value {
  padding-bottom: 1.5rem;
}
.js-core-desc {
  padding: 4rem 1rem 0 1rem;
}
@media only screen and (min-width: 769px) {
  .js-core-desc {
    padding: 4.5rem 10rem 0 10rem;
  }
}
</style>
