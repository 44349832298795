<template>
  <div>
    <h2 class="js-global-subtitle">What I'm currently working on</h2>
    <div class="js-global-inner-section js-global-extra-padding">
      <div class="columns">
        <div class="column is-full-desktop is-three-quarters-widescreen">
          <template v-if="experience.length">
            <table class="js-experience-table" width="100%">
              <template v-for="(item, index) in experience">
                <ExperienceTableItem
                  :key="`item-${index}`"
                  :item="item"
                  :index="index"
                  :experience-data="experience"
                />
              </template>
            </table>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ExperienceTableItem from '@/components/experience/ExperienceTableItem'

export default {
  name: 'CurrentlyWorkingOn',
  props: {
    experience: {
      type: Array,
      required: true,
    },
  },
  components: {
    ExperienceTableItem,
  },
}
</script>

<style scoped>
.js-experience-table th {
  padding-bottom: 0.5rem;
}
</style>
