<template>
  <div>
    <div class="js-upmost-margin" />

    <section class="js-global-section">
      <div class="columns">
        <div
          class="column is-one-third"
          data-aos="fade-right"
          data-aos-duration="1000"
        >
          <WhoIAm />
        </div>
        <div
          class="column is-two-thirds"
          data-aos="fade-left"
          data-aos-duration="1000"
        >
          <ProfilePhoto />
        </div>
      </div>
    </section>

    <div class="js-global-section-margin" />

    <section class="js-global-section">
      <Contact />
    </section>

    <div class="js-global-section-margin" />

    <section class="js-global-section">
      <CoreValues />
    </section>

    <div class="js-global-section-margin" />

    <section class="js-global-section">
      <ExperienceInYears :experience="experienceInMonths" />
    </section>

    <div class="js-global-section-margin" />

    <section class="js-global-section">
      <IndustryKnowledge :experience="experienceInMonths" />
    </section>

    <div class="js-global-section-margin" />

    <section class="js-global-section">
      <CurrentlyWorkingOn :experience="sortedExperienceCurrent" />
      <p class="js-p-margin">
        For the complete work history, visit my
        <a href="https://www.linkedin.com/in/jetrosuni/" rel="nofollow"
          >LinkedIn</a
        >
        profile.
      </p>
    </section>

    <div class="js-global-section-margin" />
  </div>
</template>

<script>
import Contact from '@/components/Contact.vue'
import CoreValues from '@/components/CoreValues.vue'
import CurrentlyWorkingOn from '@/components/CurrentlyWorkingOn.vue'
import ExperienceInYears from '@/components/ExperienceInYears.vue'
import IndustryKnowledge from '@/components/IndustryKnowledge.vue'
import ProfilePhoto from '@/components/ProfilePhoto.vue'
import WhoIAm from '@/components/WhoIAm.vue'

import helpers from '@/utils/helpers'

import experienceData from '@/data/experienceData'

export default {
  name: 'MainPage',
  data() {
    return {
      experienceInMonths: {},
      sortedExperienceCurrent: [],
      experienceData,
    }
  },
  mounted() {
    this.sortedExperienceCurrent = this.experienceData.filter(
      (e) => e.isCurrent
    )
    this.experienceInMonths = helpers.getExpInMonths(
      this.experienceData,
      new Date()
    )
  },
  components: {
    Contact,
    CoreValues,
    CurrentlyWorkingOn,
    ExperienceInYears,
    IndustryKnowledge,
    ProfilePhoto,
    WhoIAm,
  },
}
</script>

<style scoped>
.js-upmost-margin {
  margin-top: 0;
}
.js-p-margin {
  margin: 4rem 2.5rem 4rem 2.5rem;
}
@media only screen and (min-width: 1023px) {
  .js-upmost-margin {
    margin-top: 5rem;
  }
}
</style>
