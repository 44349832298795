const experienceData = [
  // Current experience
  {
    group: 'fountainhead',
    desc:
      'Active position: Founder',
    startDate: new Date(2021, 1, 24),
    isCurrent: true,
    keywords: [
      'architecture',
      'business',
      'design',
      'development',
      'it',
      'freelance',
    ]
  },
  {
    group: 'fountainhead',
    desc:
      'Freelance IT consulting & web development',
    startDate: new Date(2021, 1, 24),
    isCurrent: true,
    keywords: [
      'architecture',
      'business',
      'design',
      'development',
    ]
  },
  {
    group: 'twisted-nerve',
    desc:
      'Active position: CEO, board member and co-founder of Twisted Nerve Ltd.',
    startDate: new Date(2011, 6, 1),
    isCurrent: true,
    keywords: [
      'business',
      'leadership',
      'gaming'
    ]
  },
  {
    group: 'twisted-nerve',
    desc:
      'Business management, game development & design',
    startDate: new Date(2011, 6, 1),
    isCurrent: true,
    keywords: [
      'development',
      'design',
    ]
  },
  {
    group: 'elitisti',
    desc:
      'Active position: Editor-in-chief, lead developer and founder of the web publication',
    startDate: new Date(2003, 7, 1),
    isCurrent: true,
    keywords: [
      'leadership',
      'film'
    ]
  },
  {
    group: 'elitisti',
    desc: 'Running the publication & writing articles',
    startDate: new Date(2018, 11, 1),
    isCurrent: true,
    keywords: [
      'development',
      'architecture',
      'design',
      'film'
    ]
  },
  {
    group: 'health-bar',
    desc:
      'Health Bar health platform development, architecture planning & design',
    startDate: new Date(2017, 8, 1),
    isCurrent: false,
    keywords: [
      'development',
      'architecture',
      'design',
      'health'
    ]
  },
  {
    group: 'personal',
    desc: 'Investing, stock screening & company analysis',
    startDate: new Date(2013, 0, 1),
    keywords: [
      'business',
    ]
  },
  // Past experience
  {
    group: 'futurice',
    desc: 'Active position: Operations Development Director',
    startDate: new Date(2019, 11, 1),
    endDate: new Date(2021, 2, 20),
    isCurrent: false,
    keywords: [
      'business',
      'leadership',
      'it',
      'consultancy'
    ]
  },
  {
    group: 'futurice',
    desc: 'Product owner and lead developer of a large-scale in-house ERP system',
    startDate: new Date(2019, 0, 1),
    endDate: new Date(2021, 2, 20),
    isCurrent: false,
    keywords: [
      'development',
      'architecture',
      'design',
      'business',
      'leadership',
      'it',
      'consultancy'
    ]
  },
  {
    group: 'futurice',
    desc:
      'Acting as a supervisor for 11 senior developers of which 2 are supervisors themselves',
    startDate: new Date(2015, 3, 3),
    endDate: new Date(2021, 2, 20),
    isCurrent: false,
    keywords: [
      'business',
      'coaching',
      'leadership',
    ]
  },
  {
    group: 'futurice',
    desc:
      'Personal coaching & mentoring for a selected set of Futurice employees with various backgrounds',
    startDate: new Date(2015, 9, 1),
    endDate: new Date(2021, 2, 20),
    isCurrent: false,
    keywords: [
      'leadership',
      'coaching'
    ]
  },
  {
    group: 'mrc',
    desc:
      'Active position: Mikkeller Running Club Helsinki chapter captain',
    startDate: new Date(2017, 9, 1),
    endDate: new Date(2020, 8, 1),
    isCurrent: false,
    keywords: [
      'leadership',
      'health',
      'consultancy'
    ]
  },
  {
    group: 'futurice',
    desc: 'Active position: Business Director',
    startDate: new Date(2015, 2, 1),
    endDate: new Date(2019, 11, 1),
    keywords: [
      'business',
      'leadership',
      'it',
      'consultancy'
    ]
  },
  {
    group: 'futurice',
    desc:
      'P&L responsible operations director of a business unit of ~60 people',
    startDate: new Date(2015, 2, 2),
    endDate: new Date(2019, 11, 1),
    keywords: [
      'business',
      'leadership',
      'it',
      'consultancy'
    ]
  },
  {
    group: 'futurice',
    desc: 'Doing interviews for leadership positions',
    startDate: new Date(2018, 5, 1),
    endDate: new Date(2019, 6, 1),
    keywords: [
      'leadership',
      'it',
      'consultancy'
    ]
  },
  {
    group: 'futurice',
    desc: 'Working as a developer recruiter',
    startDate: new Date(2015, 2, 1),
    endDate: new Date(2018, 4, 1),
    keywords: [
      'leadership',
      'it',
      'consultancy'
    ]
  },
  {
    group: 'futurice',
    desc: 'Working as a senior software developer in a consultancy company',
    startDate: new Date(2012, 9, 1),
    endDate: new Date(2015, 2, 1),
    keywords: [
      'development',
      'architecture',
      'it',
      'consultancy'
    ]
  },
  {
    group: '9m2',
    desc:
      'Recipe design, water chemistry, hop, yeast & malt profile study, brewing & cleaning',
    startDate: new Date(2015, 11, 1),
    endDate: new Date(2018, 6, 1),
    keywords: [
      'brewing'
    ]
  },
  {
    group: 'twisted-nerve',
    desc: 'Game design, development and prototyping',
    startDate: new Date(2011, 0, 1),
    endDate: new Date(2018, 11, 1),
    keywords: [
      'development',
      'architecture',
      'design',
      'gaming'
    ]
  },
  {
    group: 'elitisti',
    desc:
      'Community manager and moderator',
    startDate: new Date(2010, 11, 1),
    endDate: new Date(2003, 8, 1),
    keywords: [
      'leadership',
      'film'
    ]
  },
  // Ancient experience
  {
    group: 'personal',
    desc: 'Hobbyist game development',
    startDate: new Date(1987, 0, 1),
    endDate: new Date(2005, 0, 1),
    keywords: [
      'development',
      'gaming'
    ]
  },
  {
    group: 'personal',
    desc: 'Craft beer related activities',
    startDate: new Date(2010, 0, 1),
    endDate: new Date(2019, 9, 1),
    keywords: [
      'brewing'
    ]
  },
  {
    group: 'various',
    desc: 'Working as a software developer in a consultancy company',
    startDate: new Date(2008, 7, 1),
    endDate: new Date(2015, 2, 1),
    keywords: [
      'development',
      'architecture',
      'it',
      'consultancy'
    ]
  },
  {
    group: 'various',
    desc: 'Working as a software architect in a consultancy company',
    startDate: new Date(2008, 7, 1),
    endDate: new Date(2015, 2, 1),
    keywords: [
      'development',
      'architecture',
      'it',
      'consultancy'
    ]
  },
  {
    group: 'various',
    desc: 'Working as a software developer in a product house',
    startDate: new Date(2003, 10, 1),
    endDate: new Date(2008, 6, 1),
    keywords: [
      'development',
      'architecture',
      'it',
      'product'
    ]
  },
  {
    group: 'various',
    desc: 'Working as a software architect in a product house',
    startDate: new Date(2003, 10, 1),
    endDate: new Date(2008, 6, 1),
    keywords: [
      'development',
      'architecture',
      'it',
      'product'
    ]
  },
  {
    group: 'various',
    desc: 'Employee representative',
    startDate: new Date(2008, 8, 1),
    endDate: new Date(2011, 7, 1),
    keywords: [
      'business',
      'leadership',
      'it',
      'consultancy'
    ]
  }
]

export default experienceData
