const coreValueData = {
  continuousImprovement: {
    lines: [
      "A value that can and should be applied to anything and everything.",
      "Whatever you do, you can always learn more about the subject and do things better and more efficiently the next time.",
    ]
  },
  dataInformed: {
    lines: [
      "To be able to improve continuously, one should have high-quality data to see where the trends are heading and how to correct the course when actions are needed.",
      "Being data-informed instead of being data-driven is the way I like to run things, as it's a more human-centric approach and the data you have collected might not always be telling the full story but only a part of it.",
    ]
  },
  deliberatePractice: {
    lines: [
      "When you’re practicing a new skill, it makes little to no sense to just dabble around the subject randomly.",
      "With a systematic approach to learning, you reap the benefits of saving time by learning the essentials in a meaningful order and in the end understanding the whole concept more thoroughly.",
    ]
  },
  socialIntelligence: {
    lines: [
      "While working with different people with varying backgrounds and unique characteristics, it’s essential to have a good set of social skills and psychological understanding about the norms around us, how the social circles work and what makes each one of us tick.",
      "If you use the same approach and communication style with every individual you meet, your impact won’t be as effective as it could.",
    ]
  },
  trustworthiness: {
    lines: [
      "If one can’t be trusted to take responsibility for the task they’ve promised to deliver, it’s not going to matter much what other skills or talent the person possesses.",
      "It takes two to trust. Trust is both earned and given.",
    ]
  }
}

export default coreValueData
