// Vue
import Vue from 'vue'
import router from './router'

// Buefy
import { Icon, Progress } from 'buefy'
import 'buefy/dist/buefy.css'
import './assets/scss/app.scss'

// AOS
import AOS from 'aos'
import 'aos/dist/aos.css'

// Google Analytics
import VueGtag from 'vue-gtag'

// Main App
import App from './App.vue'

Vue.use(Icon)
Vue.use(Progress)
Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_JETROSUNI_COM_GA_ID }
})

Vue.config.productionTip = false
Vue.config.performance = process.env.NODE_ENV !== "production";

new Vue({
  created() {
    AOS.init()
  },
  router,
  render: h => h(App),
}).$mount('#app')
