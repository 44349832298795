var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"js-global-subtitle"},[_vm._v("Industry knowledge in years")]),_c('div',{staticClass:"js-global-inner-section js-global-extra-padding"},[_c('ExperienceProgress',{attrs:{"type":"is-it","title":"Information Technology","expInMonths":_vm.experience.it}}),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-one-fifth is-two-fifths-tablet is-one-fifth-widescreen is-hidden-mobile"}),_c('div',{staticClass:"column is-two-fifths is-hidden-mobile"},[_c('b-progress',{staticClass:"js-bar-margin",attrs:{"type":"is-itc","value":+(_vm.getSubExperienceMultiplier() * _vm.experience.consultancy).toFixed(
              1
            ),"show-value":""}},[_c('span',{staticClass:"has-text-white"},[_vm._v(_vm._s((_vm.experience.consultancy / 12).toFixed(1))+" years")])])],1),_vm._m(0),_c('div',{staticClass:"column is-three-fifths is-hidden-tablet"},[_c('table',[_c('tr',[_c('td',{staticClass:"js-wide-width"},[_c('b-progress',{staticClass:"js-bar-margin",attrs:{"type":"is-itc","value":+(
                    _vm.getSubExperienceMultiplier() * _vm.experience.consultancy
                  ).toFixed(1),"show-value":""}},[_c('span',{staticClass:"has-text-white"},[_vm._v(_vm._s((_vm.experience.consultancy / 12).toFixed(1))+" years")])])],1),_c('td',{staticClass:"is-size-7 js-no-wrap js-mobile-pad"},[_vm._v(" Consultancy Companies ")])])])]),_c('div',{staticClass:"column is-one-fifth is-hidden-touch"})]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-one-fifth is-two-fifths-tablet is-one-fifth-widescreen is-hidden-mobile"}),_c('div',{staticClass:"column is-two-fifths is-hidden-mobile"},[_c('b-progress',{staticClass:"js-bar-margin",attrs:{"type":"is-itp","value":+(_vm.getSubExperienceMultiplier() * _vm.experience.product).toFixed(1),"show-value":""}},[_c('span',{staticClass:"has-text-black"},[_vm._v(_vm._s((_vm.experience.product / 12).toFixed(1))+" years")])])],1),_vm._m(1),_c('div',{staticClass:"column is-three-fifths is-hidden-tablet"},[_c('table',[_c('tr',[_c('td',{staticClass:"js-wide-width"},[_c('b-progress',{staticClass:"js-bar-margin",attrs:{"type":"is-itp","value":+(
                    _vm.getSubExperienceMultiplier() * _vm.experience.product
                  ).toFixed(1),"show-value":""}},[_c('span',{staticClass:"has-text-black"},[_vm._v(_vm._s((_vm.experience.product / 12).toFixed(1))+" years")])])],1),_c('td',{staticClass:"is-size-7 js-no-wrap js-mobile-pad"},[_vm._v("Product Houses")])])])]),_c('div',{staticClass:"column is-one-fifth is-hidden-touch"})]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-one-fifth is-two-fifths-tablet is-one-fifth-widescreen is-hidden-mobile"}),_c('div',{staticClass:"column is-two-fifths is-hidden-mobile"},[_c('b-progress',{staticClass:"js-bar-margin",attrs:{"type":"is-itf","value":+(_vm.getSubExperienceMultiplier() * _vm.experience.freelance).toFixed(1),"show-value":""}},[_c('span',{staticClass:"has-text-black"},[_vm._v(_vm._s((_vm.experience.freelance / 12).toFixed(1))+" years")])])],1),_vm._m(2),_c('div',{staticClass:"column is-three-fifths is-hidden-tablet"},[_c('table',[_c('tr',[_c('td',{staticClass:"js-wide-width"},[_c('b-progress',{staticClass:"js-bar-margin",attrs:{"type":"is-itf","value":+(
                    _vm.getSubExperienceMultiplier() * _vm.experience.freelance
                  ).toFixed(1),"show-value":""}},[_c('span',{staticClass:"has-text-black"},[_vm._v(_vm._s((_vm.experience.freelance / 12).toFixed(1))+" years")])])],1),_c('td',{staticClass:"is-size-7 js-no-wrap js-mobile-pad"},[_vm._v("Freelance")])])])]),_c('div',{staticClass:"column is-one-fifth"})])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column is-one-fifth is-size-7 is-hidden-mobile"},[_c('div',{staticClass:"js-global-align-left js-no-wrap is-hidden-touch"},[_vm._v(" Consultancy Companies ")]),_c('div',{staticClass:"js-global-align-right js-no-wrap is-hidden-desktop"},[_vm._v(" Consultancy Companies ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column is-one-fifth is-size-7 is-hidden-mobile"},[_c('div',{staticClass:"js-global-align-left js-no-wrap is-hidden-touch"},[_vm._v(" Product Houses ")]),_c('div',{staticClass:"js-global-align-right js-no-wrap is-hidden-desktop"},[_vm._v(" Product Houses ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column is-one-fifth is-size-7 is-hidden-mobile"},[_c('div',{staticClass:"js-global-align-left js-no-wrap is-hidden-touch"},[_vm._v(" Freelance ")]),_c('div',{staticClass:"js-global-align-right js-no-wrap is-hidden-desktop"},[_vm._v(" Freelance ")])])}]

export { render, staticRenderFns }