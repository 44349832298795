<template>
  <tr>
    <td
      align="right"
      :class="[
        index !== 0 &&
        experienceData[index].group === experienceData[index - 1].group &&
        (index - 2 < 0 ||
          experienceData[index].group !== experienceData[index - 2].group)
          ? 'is-first-same-as-prev'
          : index !== 0 &&
            experienceData[index].group === experienceData[index - 1].group
          ? 'is-same-as-prev'
          : '',
      ]"
    >
      <div
        v-if="
          index === 0 ||
          experienceData[index].group !== experienceData[index - 1].group
        "
        class="js-logo-slot"
        :class="[
          index > 0 &&
          experienceData[index - 1].group !== experienceData[index].group
            ? 'is-logo-first-diff-than-prev'
            : '',
        ]"
      >
        <div v-if="getGroupLogo(item.group)" class="js-no-link-underline">
          <a :href="getGroupLink(item.group)">
            <img
              :src="getGroupLogo(item.group)"
              width="100"
              :height="getGroupLogoHeight(item.group)"
              class="js-fixed-height js-min-width js-extra-margin"
            />
          </a>
        </div>
        <div v-else>
          {{ item.group }}
        </div>
      </div>
    </td>
    <td
      :class="[
        index > 0 &&
        experienceData[index - 1].group !== experienceData[index].group
          ? 'is-first-diff-than-prev'
          : '',
        (index > 0 &&
          index === experienceData.length - 1 &&
          experienceData[index].group === experienceData[index - 1].group) ||
        (index > 0 &&
          index < experienceData.length - 1 &&
          experienceData[index + 1].group !== experienceData[index].group &&
          experienceData[index - 1].group === experienceData[index].group)
          ? 'js-desc js-desc-last'
          : 'js-desc',
      ]"
    >
      <div :class="['is-size-6-touch', 'is-size-6-desktop']">
        {{ item.desc }}
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'ExperienceTableItem',
  props: {
    experienceData: {
      default: () => [],
      type: Array,
    },
    index: {
      default: () => 0,
      type: Number,
    },
    item: {
      default: () => {},
      type: Object,
    },
  },
  methods: {
    getGroupLink(group) {
      switch (group) {
        case 'elitisti':
          return 'https://www.elitisti.fi/'
        case 'fountainhead':
          return 'https://www.fountainhead.fi/'
        case 'twisted-nerve':
          return 'https://www.twistednerve.fi/'
      }
    },
    getGroupLogo(group) {
      switch (group) {
        case 'elitisti':
          return require('@/assets/logos/logo-elitisti.svg')
        case 'fountainhead':
          return require('@/assets/logos/logo-fountainhead.svg')
        case 'twisted-nerve':
          return require('@/assets/logos/logo-twisted-nerve.png')
      }
    },
    getGroupLogoHeight(group) {
      switch (group) {
        case 'elitisti':
          return 26
        case 'fountainhead':
          return 9
        case 'twisted-nerve':
          return 46
      }
    },
    capitalize(str) {
      const pieces = str.split('-')

      for (let i = 0; i < pieces.length; i++) {
        const j = pieces[i].charAt(0).toUpperCase()
        pieces[i] = j + pieces[i].substr(1)
      }

      return pieces.join(' ')
    },
  },
}
</script>

<style scoped>
.js-experience-table td {
  height: 3rem;
  min-height: 3rem;
  max-height: 3rem;
  vertical-align: top;
  padding-bottom: 0.5rem;
}
.is-first-diff-than-prev {
  padding-top: 3.5rem;
  vertical-align: middle !important;
}
.is-logo-first-diff-than-prev {
  padding-top: 4.5rem;
}
.is-first-same-as-prev {
  border-top: 1px dashed #b0bec5; /* blue grey 200 */
  border-right: 1px solid #cfd8dc; /* blue grey 100 */
}
.is-same-as-prev {
  border-right: 1px solid #cfd8dc; /* blue grey 100 */
}
.js-desc {
  padding-left: 2rem;
}
.js-desc-last {
  padding-bottom: 1.5rem !important;
  border-bottom: 1px dashed #b0bec5; /* blue grey 200 */
}
.js-extra-margin {
  margin-right: 2.25rem;
}
.js-no-link-underline a {
  border-bottom: none;
}

/* NOTE: to fix the issue with Safari stretching the images */
.js-fixed-height {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
}
.js-min-width {
  min-width: 60px;
}
</style>
