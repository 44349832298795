var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{class:[
      _vm.index !== 0 &&
      _vm.experienceData[_vm.index].group === _vm.experienceData[_vm.index - 1].group &&
      (_vm.index - 2 < 0 ||
        _vm.experienceData[_vm.index].group !== _vm.experienceData[_vm.index - 2].group)
        ? 'is-first-same-as-prev'
        : _vm.index !== 0 &&
          _vm.experienceData[_vm.index].group === _vm.experienceData[_vm.index - 1].group
        ? 'is-same-as-prev'
        : '' ],attrs:{"align":"right"}},[(
        _vm.index === 0 ||
        _vm.experienceData[_vm.index].group !== _vm.experienceData[_vm.index - 1].group
      )?_c('div',{staticClass:"js-logo-slot",class:[
        _vm.index > 0 &&
        _vm.experienceData[_vm.index - 1].group !== _vm.experienceData[_vm.index].group
          ? 'is-logo-first-diff-than-prev'
          : '' ]},[(_vm.getGroupLogo(_vm.item.group))?_c('div',{staticClass:"js-no-link-underline"},[_c('a',{attrs:{"href":_vm.getGroupLink(_vm.item.group)}},[_c('img',{staticClass:"js-fixed-height js-min-width js-extra-margin",attrs:{"src":_vm.getGroupLogo(_vm.item.group),"width":"100","height":_vm.getGroupLogoHeight(_vm.item.group)}})])]):_c('div',[_vm._v(" "+_vm._s(_vm.item.group)+" ")])]):_vm._e()]),_c('td',{class:[
      _vm.index > 0 &&
      _vm.experienceData[_vm.index - 1].group !== _vm.experienceData[_vm.index].group
        ? 'is-first-diff-than-prev'
        : '',
      (_vm.index > 0 &&
        _vm.index === _vm.experienceData.length - 1 &&
        _vm.experienceData[_vm.index].group === _vm.experienceData[_vm.index - 1].group) ||
      (_vm.index > 0 &&
        _vm.index < _vm.experienceData.length - 1 &&
        _vm.experienceData[_vm.index + 1].group !== _vm.experienceData[_vm.index].group &&
        _vm.experienceData[_vm.index - 1].group === _vm.experienceData[_vm.index].group)
        ? 'js-desc js-desc-last'
        : 'js-desc' ]},[_c('div',{class:['is-size-6-touch', 'is-size-6-desktop']},[_vm._v(" "+_vm._s(_vm.item.desc)+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }