<template>
  <div>
    <h2 class="js-global-subtitle">Experience in years</h2>
    <div class="js-global-inner-section">
      <ExperienceProgress
        type="is-development"
        title="Software Development"
        :expInMonths="experience.development"
      />
      <ExperienceProgress
        type="is-leadership"
        title="Leadership &amp; People"
        :expInMonths="experience.leadership"
      />
      <ExperienceProgress
        type="is-architecture"
        title="Architecture Planning"
        :expInMonths="experience.architecture"
      />
      <ExperienceProgress
        type="is-business"
        title="Business &amp; Numbers"
        :expInMonths="experience.business"
      />
      <ExperienceProgress
        type="is-design"
        title="Design Thinking"
        :expInMonths="experience.design"
      />
      <ExperienceProgress
        type="is-coaching"
        title="Coaching &amp; Mentoring"
        :expInMonths="experience.coaching"
      />
    </div>
  </div>
</template>

<script>
import ExperienceProgress from '@/components/experience/ExperienceProgress.vue'

export default {
  name: 'ExperienceInYears',
  props: {
    experience: {
      type: Object,
      required: true,
    },
  },
  components: {
    ExperienceProgress,
  },
}
</script>

<style scoped>
th.js-exp-slot {
  text-align: center !important;
  width: 2rem;
}
</style>
