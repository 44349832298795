<template>
  <div id="app">
    <Header />
    <div class="js-main container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'

export default {
  name: 'app',
  components: {
    Header,
  },
}
</script>

<style scoped>
.js-main {
  padding: 0 2rem;
}
</style>

<style>
/* global styles */
#app {
  font-family: 'Lato', 'Avenir', sans-serif;
  font-size: 1.1rem;
  line-height: 2rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #fefefe;
}
a {
  color: #263238; /* blueGrey 900 */
  border-bottom: dashed 1px #607d8b; /* blueGrey 500 */
}
.js-global-align-left {
  text-align: left;
}
.js-global-align-right {
  text-align: right;
}
.js-global-section-margin {
  margin-top: 5rem;
}
.js-global-section-margin-slim {
  padding-top: 2.5rem;
}
.js-global-section {
  text-align: left;
}
.js-global-subtitle {
  color: #4b636e;
  position: absolute;
  padding-left: 6rem;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
}
.js-global-subtitle:after {
  content: '';
  width: 4.8rem;
  height: 2px;
  background: #a7c0cd;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.js-global-inner-section {
  padding-top: 4rem;
}
@media only screen and (min-width: 769px) {
  .js-global-inner-section {
    padding-top: 4.5rem;
    padding-left: 2rem;
  }
}
.js-global-extra-padding {
  padding-top: 6.5rem;
}
@media only screen and (min-width: 496px) {
  .js-global-extra-padding {
    padding-top: 5rem;
  }
}
</style>
