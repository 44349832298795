<template>
  <div>
    <h2 class="js-global-subtitle">Industry knowledge in years</h2>
    <div class="js-global-inner-section js-global-extra-padding">
      <ExperienceProgress
        type="is-it"
        title="Information Technology"
        :expInMonths="experience.it"
      />
      <div class="columns">
        <div
          class="
            column
            is-one-fifth
            is-two-fifths-tablet
            is-one-fifth-widescreen
            is-hidden-mobile
          "
        ></div>
        <!-- desktop view -->
        <div class="column is-two-fifths is-hidden-mobile">
          <b-progress
            class="js-bar-margin"
            type="is-itc"
            :value="
              +(getSubExperienceMultiplier() * experience.consultancy).toFixed(
                1
              )
            "
            show-value
          >
            <span class="has-text-white"
              >{{ (experience.consultancy / 12).toFixed(1) }} years</span
            >
          </b-progress>
        </div>
        <div class="column is-one-fifth is-size-7 is-hidden-mobile">
          <div class="js-global-align-left js-no-wrap is-hidden-touch">
            Consultancy Companies
          </div>
          <div class="js-global-align-right js-no-wrap is-hidden-desktop">
            Consultancy Companies
          </div>
        </div>
        <!-- mobile view -->
        <div class="column is-three-fifths is-hidden-tablet">
          <table>
            <tr>
              <td class="js-wide-width">
                <b-progress
                  class="js-bar-margin"
                  type="is-itc"
                  :value="
                    +(
                      getSubExperienceMultiplier() * experience.consultancy
                    ).toFixed(1)
                  "
                  show-value
                >
                  <span class="has-text-white"
                    >{{ (experience.consultancy / 12).toFixed(1) }} years</span
                  >
                </b-progress>
              </td>
              <td class="is-size-7 js-no-wrap js-mobile-pad">
                Consultancy Companies
              </td>
            </tr>
          </table>
        </div>
        <!-- ... -->
        <div class="column is-one-fifth is-hidden-touch"></div>
      </div>

      <div class="columns">
        <div
          class="
            column
            is-one-fifth
            is-two-fifths-tablet
            is-one-fifth-widescreen
            is-hidden-mobile
          "
        ></div>
        <!-- desktop view -->
        <div class="column is-two-fifths is-hidden-mobile">
          <b-progress
            class="js-bar-margin"
            type="is-itp"
            :value="
              +(getSubExperienceMultiplier() * experience.product).toFixed(1)
            "
            show-value
          >
            <span class="has-text-black"
              >{{ (experience.product / 12).toFixed(1) }} years</span
            ></b-progress
          >
        </div>
        <div class="column is-one-fifth is-size-7 is-hidden-mobile">
          <div class="js-global-align-left js-no-wrap is-hidden-touch">
            Product Houses
          </div>
          <div class="js-global-align-right js-no-wrap is-hidden-desktop">
            Product Houses
          </div>
        </div>
        <!-- mobile view -->
        <div class="column is-three-fifths is-hidden-tablet">
          <table>
            <tr>
              <td class="js-wide-width">
                <b-progress
                  class="js-bar-margin"
                  type="is-itp"
                  :value="
                    +(
                      getSubExperienceMultiplier() * experience.product
                    ).toFixed(1)
                  "
                  show-value
                >
                  <span class="has-text-black"
                    >{{ (experience.product / 12).toFixed(1) }} years</span
                  >
                </b-progress>
              </td>
              <td class="is-size-7 js-no-wrap js-mobile-pad">Product Houses</td>
            </tr>
          </table>
        </div>
        <!-- ... -->
        <div class="column is-one-fifth is-hidden-touch"></div>
      </div>

      <div class="columns">
        <div
          class="
            column
            is-one-fifth
            is-two-fifths-tablet
            is-one-fifth-widescreen
            is-hidden-mobile
          "
        ></div>
        <!-- desktop view -->
        <div class="column is-two-fifths is-hidden-mobile">
          <b-progress
            class="js-bar-margin"
            type="is-itf"
            :value="
              +(getSubExperienceMultiplier() * experience.freelance).toFixed(1)
            "
            show-value
          >
            <span class="has-text-black"
              >{{ (experience.freelance / 12).toFixed(1) }} years</span
            ></b-progress
          >
        </div>
        <div class="column is-one-fifth is-size-7 is-hidden-mobile">
          <div class="js-global-align-left js-no-wrap is-hidden-touch">
            Freelance
          </div>
          <div class="js-global-align-right js-no-wrap is-hidden-desktop">
            Freelance
          </div>
        </div>
        <!-- mobile view -->
        <div class="column is-three-fifths is-hidden-tablet">
          <table>
            <tr>
              <td class="js-wide-width">
                <b-progress
                  class="js-bar-margin"
                  type="is-itf"
                  :value="
                    +(
                      getSubExperienceMultiplier() * experience.freelance
                    ).toFixed(1)
                  "
                  show-value
                >
                  <span class="has-text-black"
                    >{{ (experience.freelance / 12).toFixed(1) }} years</span
                  >
                </b-progress>
              </td>
              <td class="is-size-7 js-no-wrap js-mobile-pad">Freelance</td>
            </tr>
          </table>
        </div>
        <!-- ... -->
        <div class="column is-one-fifth"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ExperienceProgress from '@/components/experience/ExperienceProgress.vue'

export default {
  name: 'IndustryKnowledge',
  props: {
    experience: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getSubExperienceMultiplier() {
      const maxYears = this.experience.it
      return 100 / maxYears
    },
  },
  components: {
    ExperienceProgress,
  },
}
</script>

<style scoped>
.js-bar-margin {
  margin-top: 0.5rem;
}
.js-no-wrap {
  white-space: nowrap;
}
.js-wide-width {
  width: 60vw;
}
.js-mobile-pad {
  width: 35vw;
  padding-left: 5vw;
}
</style>

<style>
.is-itc::-webkit-progress-value {
  background-color: #78909c !important; /* blueGrey 400 */
}
.is-itp::-webkit-progress-value {
  background-color: #90a4ae !important; /* blueGrey 300 */
}
.is-itf::-webkit-progress-value {
  background-color: #b0bec5 !important; /* blueGrey 200 */
}
</style>
