<template functional>
  <div>
    <h2 class="js-global-subtitle">Who I am</h2>
    <div class="js-list-area">
      <ul class="js-main-list">
        <li
          class="
            is-hidden-mobile
            is-hidden-tablet-only
            is-hidden-desktop-only
            is-hidden-widescreen-only
          "
        ></li>
        <li>Creator</li>
        <li>Entrepreneur</li>
        <li>Problem solver</li>
        <li>Mentor &amp; coach</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WhoIAm',
}
</script>

<style scoped>
.js-list-area {
  padding-top: 3.5rem;
}
@media only screen and (min-width: 1408px) {
  .js-list-area {
    padding-top: 2.5rem;
  }
}
.js-main-list {
  list-style: none;
}
.js-main-list li {
  margin-bottom: 2rem;
}
</style>
