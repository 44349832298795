<template functional>
  <div class="columns">
    <div
      class="
        column
        is-three-fifths-mobile
        is-two-fifths-tablet
        is-one-fifth-widescreen
        is-size-6-widescreen
      "
    >
      <div class="js-global-align-right is-hidden-mobile is-size-6-tablet">
        {{ props.title }}
      </div>
      <div class="js-global-align-left is-hidden-tablet is-size-7-desktop">
        {{ props.title }}
      </div>
    </div>
    <div
      class="
        column
        is-three-fifths is-two-fifths-desktop is-three-fifths-widescreen
      "
    >
      <b-progress
        class="js-small-margin"
        size="is-medium"
        :type="props.type"
        :value="
          +(
            ($options.getExperienceMultiplier() * props.expInMonths) /
            12
          ).toFixed(1)
        "
        show-value
      >
        <span
          :class="[
            props.type === 'is-development' || props.type === 'is-gaming'
              ? 'has-text-white'
              : 'has-text-black',
          ]"
          >{{ (props.expInMonths / 12).toFixed(1) }} years</span
        ></b-progress
      >
    </div>
    <div
      :class="[
        props.type === 'is-it'
          ? 'column is-one-fifth is-hidden-mobile is-hidden-tablet'
          : 'column is-one-fifth',
      ]"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'ExperienceProgress',
  props: {
    title: {
      default: () => '',
      type: String,
    },
    type: {
      default: () => '',
      type: String,
    },
    expInMonths: {
      default: () => 0,
      type: Number,
    },
  },
  getExperienceMultiplier() {
    const maxYears = new Date().getFullYear() - 1979
    return 100 / maxYears
  },
}
</script>

<style scoped>
.js-small-margin {
  margin-top: 0.2rem;
}
</style>

<style>
.is-business::-webkit-progress-value {
  background-color: #03a9f4 !important;
}
.is-business::-moz-progress-bar {
  background-color: #03a9f4 !important;
}

.is-coaching::-webkit-progress-value {
  background-color: #009688 !important;
}
.is-coaching::-moz-progress-bar {
  background-color: #009688 !important;
}

.is-design::-webkit-progress-value {
  background-color: #cddc39 !important;
}
.is-design::-moz-progress-bar {
  background-color: #cddc39 !important;
}

.is-architecture::-webkit-progress-value {
  background-color: #673ab7 !important;
}
.is-architecture::-moz-progress-bar {
  background-color: #673ab7 !important;
}

.is-leadership::-webkit-progress-value {
  background-color: #4caf50 !important;
}
.is-leadership::-moz-progress-bar {
  background-color: #4caf50 !important;
}

.is-development::-webkit-progress-value {
  background-color: #607d8b !important;
}
.is-development::-moz-progress-bar {
  background-color: #607d8b !important;
}

/* Industries */

.is-it::-webkit-progress-value {
  background-color: #9e9e9e !important;
}
.is-it::-moz-progress-bar {
  background-color: #9e9e9e !important;
}

.is-itc::-webkit-progress-value {
  background-color: #b0bec5 !important; /* blueGrey 300 */
}
.is-itc::-moz-progress-bar {
  background-color: #b0bec5 !important; /* blueGrey 300 */
}

.is-itp::-webkit-progress-value {
  background-color: #78909c !important; /* blueGrey 400 */
}
.is-itp::-moz-progress-bar {
  background-color: #78909c !important; /* blueGrey 400 */
}

.is-journalism::-webkit-progress-value {
  background-color: #ff9800 !important;
}
.is-journalism::-moz-progress-bar {
  background-color: #ff9800 !important;
}

.is-gaming::-webkit-progress-value {
  background-color: #ff5722 !important;
}
.is-gaming::-moz-progress-bar {
  background-color: #ff5722 !important;
}

.is-beer::-webkit-progress-value {
  background-color: #ffc107 !important;
}
.is-beer::-moz-progress-bar {
  background-color: #ffc107 !important;
}

.is-health::-webkit-progress-value {
  background-color: #8bc34a !important;
}
.is-health::-moz-progress-bar {
  background-color: #8bc34a !important;
}
</style>