<template functional>
  <div class="box js-box" data-aos="fade-up">
    <article class="media js-values">
      <div class="media-content">
        <div class="js-content">
          <div class="js-icon-wrapper">
            <div class="js-icon">
              <b-icon
                :pack="props.pack"
                :icon="props.icon"
                size="is-large"
                :class="props.iconClass"
              >
              </b-icon>
            </div>
            <div class="js-value-title is-size-5">
              {{ props.title }}
            </div>
          </div>
          <div class="is-size-6">
            <ul
              v-for="(line, index) in props.lines"
              :key="`${props.title}-${index}`"
            >
              <li class="js-line">{{ line }}</li>
            </ul>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: 'CoreValue',
  props: {
    icon: {
      default: () => '',
      type: String,
    },
    pack: {
      default: () => '',
      type: String,
    },
    iconClass: {
      default: () => 'js-icon-grey',
      type: String,
    },
    title: {
      default: () => '',
      type: String,
    },
    lines: {
      default: () => [],
      type: Array,
    },
    practice: {
      default: () => '',
      type: String,
    },
  },
}
</script>

<style scoped>
.js-box {
  height: 100%;
}
.js-values {
  padding: 1rem;
}
.js-value-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  padding-top: 0.5rem;
  margin-bottom: 1.5rem;
}
.js-icon {
  float: left;
  padding-right: 1rem;
}
.js-content {
  padding-left: 1rem;
}
.js-line {
  margin-top: 1rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
/* icon colors */
.js-icon-ci {
  color: #ff5722; /* deep orange 500 */
}
.js-icon-data {
  color: #002984; /* indigo 500 dark */
}
.js-icon-practice {
  color: #34515e; /* blue grey 500 dark */
}
.js-icon-intelligence {
  color: #5a9216; /* light green 500 dark */
}
.js-icon-trust {
  color: #ec407a; /* pink 400 */
}
.js-icon-grey {
  color: #424242; /* grey 800 */
}
</style>